import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TreePopup from '../../components/TreePopup';
import LocationValidation from '../Validation/LocationValidation';
import SegmentationValidation from './SegmentationValidation';
import TreeAdd from '../Validation/TreeAdd';
import SemanticValidation from './SemanticValidation';
import TreeSelector from '../../components/HeaderBar/TreeSelector';
import {
  BetaManagedAreasContextProvider,
  MANAGED_AREA_STATUSES,
  useBetaManagedAreasContext,
} from '../../hooks/betaHooks/beta-managed-areas-context';
import { useRouteMatch } from 'react-router';
import { BetaManagedAreaContextProvider, useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import SkeletonWithManagedAreaMap from '../../layout/SkeletonWithManagedAreaMap';
import { ScreenTypes } from '../../hooks/betaHooks/beta-use-tree';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import getCurrentValidationStepFromPath from './libs/getValidationStep';
import { useBetaTreeIndex } from '../../hooks/betaHooks/beta-use-tree-index';

export const TODO_TREE_STATUSES = [
  TreeFlowStatus.LocationValidationQueued,
  TreeFlowStatus.SegmentationValidationQueued,
  TreeFlowStatus.MeasurementValidationQueued,
  TreeFlowStatus.SentToOnlineAnnotationQueued,
  TreeFlowStatus.LocationValidationRunning,
  TreeFlowStatus.SegmentationValidationRunning,
  TreeFlowStatus.MeasurementValidationRunning,
  TreeFlowStatus.SentToOnlineAnnotationRunning,
]

const typeOfValidation = (pipeline) => {
  if (pipeline?.current_manual_step === 'location_validation') return 'location';
  if (pipeline?.current_manual_step === 'semantic_validation') return 'semantic';
  if (pipeline?.current_manual_step === 'segmentation_validation') return 'segmentation';
  if (pipeline?.current_manual_step === 'db_match') return 'db_match';
  return null;
};

const generatePhases = (generate, isTreeDone) => {
  if (generate) {
    return [
      {
        status: 'current',
        progress: isTreeDone ? 'complete' : 'incomplete',
        title: 'Validation',
        onClick: () => {},
      },
    ];
  }
  return [];
};

// Note: if no 'statuses', 'id' is used as status.
const layerFilter = [
  // {
  //   id: 'maven',
  //   source: 'maven',
  //   type: 'circle',
  // },
  {
    id: 'sent_to_field',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.SentToField],
  },
  {
    id: 'validation_todo',
    source: 'trees',
    type: 'circle',
    statuses: [TreeFlowStatus.LocationValidationQueued, TreeFlowStatus.SentToOnlineAnnotationQueued, TreeFlowStatus.SegmentationValidationQueued, TreeFlowStatus.MeasurementValidationQueued],
  },
  {
    id: 'validation_done',
    source: 'trees',
    type: 'circle',
    statuses: [
      TreeFlowStatus.LocationValidationDone,
      TreeFlowStatus.SegmentationValidationDone,
      TreeFlowStatus.MeasurementValidationDone,
      TreeFlowStatus.Completed,
    ],
  },
  {
    id: 'validation_deleted',
    source: 'trees',
    type: 'circle',
    statuses: [
      TreeFlowStatus.LocationValidationDeleted,
      TreeFlowStatus.SegmentationValidationDeleted,
      TreeFlowStatus.MeasurementValidationDeleted,
    ],
  },
  /*
    {
      id: 'location_validation_todo',
      source: 'trees',
      type: 'circle',
    },
    {
      id: 'location_validation_done',
      source: 'trees',
      type: 'circle',
    },
    {
      id: 'semantic_validation_todo',
      source: 'trees',
      type: 'circle',
    },
    {
      id: 'semantic_validation_done',
      source: 'trees',
      type: 'circle',
    },
    {
      id: 'db_match_todo',
      source: 'trees',
      type: 'circle',
    },
    {
      id: 'completed',
      source: 'trees',
      type: 'circle',
    },*/
];

const generateNavigationTabs = ({ path, onPathChange = () => {}, onChangeTree, currentTree, managedArea }) => {
  return [
    {
      type: 'link',
      path: `/validation`,
      active: /\/validation/.test(path),
      onClick: () => onPathChange(''),
      icon: 'map',
      titleOnHover: 'Jump to the Organization',
    },
    {
      type: 'link',
      active: /\/validation\/\d*/.test(path),
      onClick: () => onPathChange(''),
      title: managedArea?.code,
      titleOnHover: 'Jump to the Managed Area',
    },
    {
      type: 'component',
      active: /semantics\/\d/.test(path),
      params: {
        onChangeTree,
        currentTree,
      },
      component: TreeSelector,
    },
    {
      type: 'component',
      active: /segmentation\/\d/.test(path),
      params: {
        onChangeTree,
        currentTree,
      },
      component: TreeSelector,
    },
    {
      type: 'component',
      active: /location\/\d/.test(path),
      params: {
        onChangeTree,
        currentTree,
      },
      component: TreeSelector,
    },
  ];
};

const tableColumns =
  (pipeline, sortTrees, jumpToTree) =>
  (selection, isProximityVisible = false, proximityAlertTreeIDs = []) => {
    return selection
      ? [
          {
            title: 'ID',
            key: 'id',
            resolver: 'id',
            sortable: true,
            onSort: sortTrees,
            format: 'ESS_ID',
            clickNavigation: (entry) => jumpToTree(entry, pipeline),
          },
          {
            title: 'Confidence level',
            key: 'confidence_level',
            resolver: (row) => {
              const validation = typeOfValidation(pipeline);
              if (validation === 'location') return row.location_confidence;
              if (validation === 'segmentation') return row.ml_instance_confidence;
              if (validation === 'semantic') return row.confidence_girth_ellipse;
              return null;
            },
            format: 'PERCENTAGE',
            sortable: true,
            onSort: (key, direction) => {
              const validation = typeOfValidation(pipeline);
              if (key === 'confidence_level') {
                if (validation === 'location') key = 'location_confidence';
                if (validation === 'segmentation') key = 'ml_instance_confidence';
                if (validation === 'semantic') key = 'confidence_girth_ellipse';
              }
              sortTrees(key, direction);
            },
          },
          ...(pipeline?.current_manual_step === 'segmentation_validation'
            ? [
                {
                  title: 'Confidence level 2',
                  key: 'confidence_level_2',
                  resolver: (row) => {
                    const validation = typeOfValidation(pipeline);
                    if (validation !== 'segmentation') return null;
      
                    return row.ml_semseg_confidence;
                  },
                  format: 'PERCENTAGE',
                  sortable: true,
                  onSort: (key, direction) => {
                    if (key === 'confidence_level_2') {
                      if (typeOfValidation(pipeline) !== 'segmentation') return null;
      
                      key = 'ml_semseg_confidence';
                    }
      
                    sortTrees(key, direction);
                  },
                },
              ]
            : []),
          {
            title: 'Status',
            resolver: 'tree_flow_status',
            key: 'tree_flow_status',
            format: 'STATUS',
            sortable: true,
            onSort: sortTrees,
          },
          {
            title: 'TMS Category',
            resolver: ({ tms_category }) => (tms_category ? tms_category.toUpperCase() : '-'),
            key: 'tms_category',
            sortable: true,
          },
          {
            title: 'Manually corrected',
            resolver: ({ manually_corrected }) => (manually_corrected ? 'YES' : 'NO'),
            key: 'manually_corrected',
            sortable: true,
          },
          {
            title: 'Comment',
            key: 'comment',
            resolver: ({ comment }) => (comment ? comment.map(({ value }) => value).join(', ') : '-'),
            sortable: true,
            onSort: sortTrees,
          },
          ...(isProximityVisible
            ? [
                {
                  title: 'Proximity Alert',
                  key: 'proximity',
                  resolver: (it) => (proximityAlertTreeIDs.includes(it.id) ? 'issue' : 'correct'),
                  sortable: true,
                },
              ]
            : []),
          ...(pipeline?.current_manual_step === 'location_validation'
            ? [
                {
                  title: 'Actions',
                  sortable: false,
                  uniqueKey: 'action',
                  format: 'REVERT_STATUS_BUTTON',
                },
              ]
            : []),
        ]
      : [
          // {
          //   title: 'Location name',
          //   key: 'location',
          //   resolver: 'description',
          //   sortable: true,
          // },
          {
            title: 'Area code',
            key: 'code',
            resolver: 'code',
            format: 'CODE',
            sortable: true,
          },
          {
            title: 'Status',
            key: 'status',
            resolver: 'current_manual_step',
            format: 'STATUS',
            sortable: true,
          },
          /*Removed in PRD-370*/
          /* {
                       title: 'TI month',
                       key: 'ti_month',
                       resolver: 'next_inspection',
                       format: 'MONTH',
                       sortable: true,
                     },*/
          {
            title: 'OIC',
            key: 'officer_in_charge',
            resolver: (ma) => (ma.oic_full_name ? ma.oic_full_name : ma.oic_user_name) || '-',
            sortable: true,
          },
          /*Removed in PRD-370*/
          /*{
                      title: 'Scheduled',
                      key: 'scheduled',
                      resolver: (ma) => ma.scheduled || '-',
                      sortable: true,
                    },*/
          {
            title: 'Tree number',
            key: 'tree_number',
            resolver: 'tree_number',
            sortable: true,
          },
          {
            title: 'Pending',
            key: 'pending',
            resolver: 'pending',
            sortable: true,
          },
          {
            title: 'Validated',
            key: 'validated',
            resolver: 'validated',
            sortable: true,
          },
          {
            title: 'Accepted',
            key: 'accepted',
            resolver: 'accepted',
            sortable: true,
          },
          {
            title: 'Sent to Field',
            key: 'sent_to_field',
            resolver: 'sent_to_field',
            sortable: true,
          },
          {
            title: 'Deleted',
            key: 'deleted',
            resolver: 'deleted',
            sortable: true,
          },
          {
            title: 'Manually corrected',
            key: 'manually_corrected',
            resolver: 'manually_corrected',
            sortable: true,
          },
          /*Removed in PRD-370*/
          /*{
                      title: '%',
                      key: 'progress',
                      format: 'PERCENTAGE',
                      resolver: (ma) => ma.percentage || '-',
                      sortable: true,
                    },
                    {
                      title: 'SLA',
                      key: 'sla',
                      resolver: 'last_inspection',
                      format: 'DATE',
                      sortable: true,
                    },*/
        ];
  };

const steps = {
  location_validation: 'location',
  semantic_validation: 'semantics',
  segmentation_validation: 'segmentation',
  db_match: 'dbmatch',
};

const getStartAction = (treeStatus, current_manual_step) => {
  return steps[current_manual_step];
};

const getHeaderTitle = (path) => {
  if (path.includes('location')) return 'Location';
  if (path.includes('semantics')) return 'Semantics';
  if (path.includes('segmentation')) return 'Segmentation';
  return 'Validation';
};

const ValidationRouterComponent = ({ location, history, match }) => {
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  useEffect(() => {
    betaMASCtx.reset();
    betaMASCtx.fetch();
  }, [managedAreaId]);

  useEffect(() => {
    betaMACtx.reset();

    if (!managedAreaId) return;

    betaMACtx.fetch();
  }, [managedAreaId]);

  //useEffect(() => {
  //  if (!currentTreeId) return;

  //  betaTree.reset();
  //  betaTree.fetch();
  //}, [currentTreeId]);

  const mapRef = useRef(null);
  const [selectedTreeId, setSelectedTreeId] = useState(null);
  const pipeline = betaMACtx?.pipeline;
  const managedArea = betaMACtx?.managedArea;
  const trees = betaMACtx?.sortedTrees?.length ? betaMACtx.sortedTrees : betaMACtx.trees;
  // const tree = betaTree.tree || betaMACtx.trees.find(t => t.id === selectedTreeId) || betaMACtx?.trees?.[0];
  const tree = trees?.find((t) => t.id === selectedTreeId) || trees?.[0];

  const betaMASCtxLoading = betaMASCtx.loading;

  const betaMACtxLoading = betaMACtx.loading || betaMACtx.loadingManagedAreaAndPipeline || betaMACtx.loadingTrees;

  const contextsLoadingState = betaMASCtxLoading || betaMACtxLoading;

  const selection = useMemo(() => [managedArea, managedArea?.id], [managedArea?.id]);

  const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');

  const validationStep = useMemo(() => {
    if (!managedArea?.id) return null;

    return getCurrentValidationStepFromPath();
  }, [managedArea?.id, trees?.length, window.location.pathname]);

  const todoStatuses = useMemo(() => {
    switch (validationStep) {
      case 'location':
        return [TreeFlowStatus.LocationValidationQueued];
      case 'segmentation_validation':
        return [TreeFlowStatus.SegmentationValidationQueued, TreeFlowStatus.SentToOnlineAnnotationQueued];
      case 'semantic_validation':
        return [TreeFlowStatus.MeasurementValidationQueued];
      default:
        return [];
    }
  }, [managedArea?.id, trees?.length, validationStep]);

  const todoTrees = trees.filter(t => t?.tree_flow_status && TODO_TREE_STATUSES.includes(t.tree_flow_status))

  const { moveToNeighbour, selectTree } = useBetaTreeIndex({
    trees: todoTrees,
    selectedTreeId: tree?.id || selectedTreeId,
    todoStatuses,
    setTree: (tree) => {
      history.push(`/validation/${managedArea?.id}/${validationStep}/${tree?.id}`);
    },
    skippedTrees,
  });

  const _handleTab = (path) => managedAreaId && history.push(`/validation/${managedAreaId}/${path}`);

  const _handleFilter = useCallback(
    ({ pending, current_manual_step }) => {
      const statusLogic =
        current_manual_step && betaMASCtx.maFilters?.status !== MANAGED_AREA_STATUSES.default
          ? current_manual_step === betaMASCtx.maFilters?.status
          : current_manual_step;
      const pendingFilter = betaMASCtx?.maFilters?.pending ? pending > 0 : pending >= 0;

      return pendingFilter && statusLogic;
    },
    [betaMASCtx?.maFilters?.status, betaMASCtx?.maFilters?.pending]
  );
  const tableFilter = useMemo(() => (!managedAreaId ? _handleFilter : null), [_handleFilter, managedAreaId]);

  const layerFilters = useMemo(() => {
    return layerFilter.map((layer) => ({
      ...layer,
    }));
  }, []);

  const isActionAvailable =
    pipeline &&
    Object.keys(steps).includes(pipeline?.current_manual_step) &&
    !(
      pipeline.location_proposal === 'error' ||
      pipeline.semantic_extraction === 'error' ||
      pipeline.location_proposal === 'todo' ||
      pipeline.semantic_extraction === 'todo' ||
      pipeline.location_proposal === 'started' ||
      pipeline.semantic_extraction === 'started'
    );

  const treePopup = useCallback(
    ({ feature, ...props }) => {
      const clickedTree = betaMACtx?.trees?.find((tree) => Number(tree.id) === Number(feature.id));

      const treeData = {
        ...feature,
        properties: {
          ...feature.properties,
          manual_scientific_name: clickedTree?.manual_scientific_name,
          maven_tree_id: clickedTree?.tree_id,
          spsc_nm: clickedTree?.spsc_nm,
        },
      };
      return <TreePopup tree={treeData} {...props} />;
    },
    [betaMACtx?.trees]
  );

  const jumpToTree = (tree, managedarea) => {
    const subPath = steps[managedarea?.current_manual_step];
    if (!subPath) return;
    history.push((window.location.pathname.split('/').slice(0, 4).join('/') + `/${subPath}/` + tree.id).replace('//', '/'));
    selectTree(tree);
  };
  const treeFilterForTable = useCallback(({ tree_flow_status }) => {
    const statusLogic = 
      tree_flow_status?.toLowerCase().includes("queued") ||
      tree_flow_status?.toLowerCase().includes("done") ||
      tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationQueued ||
      (
        pipeline?.current_manual_step === MANAGED_AREA_STATUSES.LOCATION_VALIDATION &&
        (
          tree_flow_status?.toLowerCase().includes("deleted") || 
          tree_flow_status?.toLowerCase().includes("field")
        )
      )

    return statusLogic;
  }, [trees, betaMASCtx?.maFilters?.status, betaMASCtx?.maFilters?.pending])

  const handleChangeTree = async (direction) => {
    if (direction === 1) {
      await betaMACtx.skipTree(tree?.id);
    } else {
      await betaMACtx.removeSkippedTree(tree?.id);
    }

    await moveToNeighbour(direction);
  };

  useEffect(() => {
    const storedManagedAreaId = localStorage.getItem('managedAreaId');

    if (storedManagedAreaId && managedArea?.id && storedManagedAreaId.toString() !== managedArea.id.toString()) {
      localStorage.removeItem('skippedTrees');
    }
  }, [managedArea?.id]);

  return (
    <SkeletonWithManagedAreaMap
      onTreesReloadRequest={() => betaMACtx.fetchTrees()}
      location={location}
      history={history}
      match={match}
      basePath='validation'
      title={getHeaderTitle(location.pathname)}
      selection={selection}
      trees={trees}
      skippedTrees={skippedTrees}
      skipTree={betaMACtx.skipTree}
      pipelines={betaMASCtx.filteredPipelines?.length ? betaMASCtx.filteredPipelines : betaMASCtx.pipelines}
      managedAreas={betaMASCtx.filteredManagedAreas?.length ? betaMASCtx.filteredManagedAreas : betaMASCtx.managedAreas}
      pipeline={betaMACtx.pipeline}
      managedArea={managedArea}
      onTreeSelection={setSelectedTreeId}
      contextsLoadingState={contextsLoadingState}
      reloadTrees={betaMACtx.fetchTrees}
      reFetchManagedAreaAndTrees={betaMACtx.fetch}
      // setTree={console.log}
      // currentTree={null}
      mapRef={mapRef}
      // !TODO -> Make sortTreesByDirection into betaTreeHook (sortTreesByDirection in old data hook)
      customColumns={tableColumns(pipeline, () => void 0, jumpToTree)}
      tableFilter={tableFilter}
      pipelineFilter={_handleFilter}
      treeFilterForTable={treeFilterForTable}
      managedAreaFilters={betaMASCtx.maFilters}
      updateManagedAreaFilters={betaMASCtx.updateFilters}
      treePopup={treePopup}
      screenType={ScreenTypes.VALIDATION}
      treeId={tree?.id}
      newTabs={generateNavigationTabs({
        path: window.location.pathname,
        onPathChange: _handleTab,
        onChangeTree: handleChangeTree,
        currentTree: tree,
        managedArea,
      })}
      /*tabs={generateTabs({
        path: location.pathname,
        pipeline,
        onPathChange: _handleTab,
        selectedTreeId,
        isActionAvailable,
      })}*/
      phases={generatePhases(/semantics\/\d/.test(location.pathname), false)}
      screens={[
        {
          title: `${managedArea?.code} | Segmentation | Validation`,
          path: '/validation/:MA/segmentation/:tree?',
          Component: SegmentationValidation,
        },
        {
          title: `${managedArea?.code} | Semantics | Validation`,
          path: '/validation/:MA/semantics/:tree?',
          Component: SemanticValidation,
        },
        {
          title: `${managedArea?.code} | Location Validation | Validation`,
          path: '/validation/:MA/location/new/:tree?',
          Component: TreeAdd,
        },
        {
          title: `${managedArea?.code} | Location Validation | Validation`,
          path: '/validation/:MA/location/:tree?',
          Component: LocationValidation,
        },
      ]}
      layerFilter={layerFilters}
      startAction={{
        isAvailable: isActionAvailable,
        step: pipeline && getStartAction(tree?.tree_flow_status, pipeline.current_manual_step),
        treeId: tree?.id,
      }}
      isMicroClimate={false}
      todoTrees={todoTrees}
    />
  );
};

const ValidationRouter = (props) => {
  const match = useRouteMatch();
  const managedAreaId = match.params?.MA ? parseInt(match.params?.MA, 10) : null;

  return (
    <BetaManagedAreasContextProvider>
      <BetaManagedAreaContextProvider managedAreaId={managedAreaId}>
        <ValidationRouterComponent {...props} />
      </BetaManagedAreaContextProvider>
    </BetaManagedAreasContextProvider>
  );
};

export default ValidationRouter;
