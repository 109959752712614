import { Link, useTheme as useMuiTheme } from '@mui/material';
import * as lodash from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Option } from '../../../@types/Autocomplete';
import { GetGenusesOptions, GetSpeciesOptions } from '../../../hooks/betaHooks/beta-use-tree';
import Autocomplete from '../../Autocomplete/Autocomplete';
import Card from '../../Card/Card';
import Leaf from '../../Icon/Leaf';
import { Button } from '../../inputs';

interface TreeClassificationGenusSpeciesCardValue {
    genus_id: number | null;
    genus_name: string | null;
    species_id: number | null;
    species_name: string | null;
}

export interface TreeClassificationGenusSpeciesCardProps {
    cardValue?: TreeClassificationGenusSpeciesCardValue;
    genusConfidenceLockEnabled: boolean;
    genusConfidenceLock: number;
    genusConfidenceValue: number | null;
    disabled: boolean;
    onGenusAndSpeciesChange?: (genusId: number | null, speciesId?: number | null) => void;
    getGenuses: (options: GetGenusesOptions) => Promise<any[]>;
    getSpecies: (options: GetSpeciesOptions) => Promise<any[]>;
    onCardStateChange: (modified: boolean) => void;
}

const TreeClassificationGenusSpeciesCard = (props: TreeClassificationGenusSpeciesCardProps) => {
    const muiTheme = useMuiTheme();

    const { genus_id, genus_name, species_id, species_name } = props.cardValue || {};
    const genusInputRef = useRef<HTMLInputElement | null>(null);
    const speciesInputRef = useRef<HTMLInputElement | null>(null);

    const {
        getGenuses: fetchGenusData,
        getSpecies: fetchSpeciesData,
        onGenusAndSpeciesChange,
        onCardStateChange
    } = props;


    const currentGenusValue = useMemo(() => genus_id && genus_name ? { value: genus_id, label: genus_name } : null,
        [genus_id, genus_name]);
    const currentSpeciesValue = useMemo(() => species_id && species_name ? { value: species_id, label: species_name } : null,
        [species_id, species_name]);

    const [genusOptions, setGenusOptions] = useState<Option<number>[]>(currentGenusValue ? [currentGenusValue] : []);
    const [speciesOptions, setSpeciesOptions] = useState<Option<number>[]>(currentSpeciesValue ? [currentSpeciesValue] : []);
    const [genusValue, setGenusValue] = useState(currentGenusValue);
    const [speciesValue, setSpeciesValue] = useState(currentSpeciesValue);

    const shouldLockGenus = useMemo(() => {
        if (props.disabled) {
            return true;
        }
        return (props.genusConfidenceLockEnabled && !!props.genusConfidenceValue &&
            props.genusConfidenceValue >= props.genusConfidenceLock && props.genusConfidenceValue !== 1);
    }, [props.genusConfidenceLockEnabled, props.genusConfidenceValue, props.genusConfidenceLock, props.disabled]);

    const shouldLockSpecies = useMemo(() => {
        return shouldLockGenus || genusValue == null;
    }, [shouldLockGenus, genusValue]);

    const saveButtonEnabled = useMemo(() =>
        currentGenusValue?.value !== genusValue?.value || currentSpeciesValue?.value !== speciesValue?.value,
        [currentGenusValue, genusValue, currentSpeciesValue, speciesValue]);

    const handleGenusChange = useCallback((value: Option<number> | null) => {
        if (speciesValue) {
            setSpeciesValue(null);
        }
        setGenusValue(value);

        if (value) {
            fetchSpeciesData({ name: '', genusId: value.value, onlyName: true })
                .then(species => setSpeciesOptions(species.map(s => ({ value: s.id, label: s.species }))));
        }
    }, [speciesValue, fetchSpeciesData]);

    const handleSaveButtonClick = useCallback(() => {
        if (currentSpeciesValue?.value === speciesValue?.value) {
            // If the species is unchanged, we do not want to update it, because that
            // will trigger a change of species confidence level too. We only want to 
            // change the species confidence level when there is an actual change to
            // the species value.
            onGenusAndSpeciesChange?.(genusValue?.value ?? null);
        }
        else {
            onGenusAndSpeciesChange?.(genusValue?.value ?? null, speciesValue?.value ?? null);
        }
    }, [genusValue, currentSpeciesValue, speciesValue, onGenusAndSpeciesChange]);

    const handleGenusBlur = useCallback(() => {
        const genusInputValue = genusInputRef.current?.value;
        if (genusInputValue) {
            const match = genusOptions.find(option => option.label.toLowerCase() === genusInputValue.toLowerCase());
            if (match && match.value !== genusValue?.value) {
                handleGenusChange(match);
            }
        }
    }, [genusOptions, handleGenusChange, genusValue?.value]);

    const handleSpeciesBlur = useCallback(() => {
        const speciesInputValue = speciesInputRef.current?.value;
        if (speciesInputValue) {
            const match = speciesOptions.find(option => option.label.toLowerCase() === speciesInputValue.toLowerCase());
            if (match && match.value !== speciesValue?.value) {
                setSpeciesValue(match);
            }
        }
    }, [speciesOptions, speciesValue?.value]);

    const refreshGenusOptions = useCallback(async (nameFilter: string) => {
        const genuses = await fetchGenusData({ name: nameFilter, onlyName: true });
        setGenusOptions(genuses.map(genus => ({ value: genus.id, label: genus.taxon_name })));
    }, [fetchGenusData]);

    const refreshSpeciesOptions = useCallback(async (nameFilter: string) => {
        if (genusValue) {
            const species = await fetchSpeciesData({ name: nameFilter, genusId: genusValue.value, onlyName: true });
            setSpeciesOptions(species.map(s => ({ value: s.id, label: s.species })));
        }
        else {
            setSpeciesOptions([]);
        }
    }, [fetchSpeciesData, genusValue]);

    const refreshGenusOptionsDebounced = useMemo(() => lodash.debounce(refreshGenusOptions, 300), [refreshGenusOptions]);

    const refreshSpeciesOptionsDebounced = useMemo(() => lodash.debounce(refreshSpeciesOptions, 300), [refreshSpeciesOptions]);

    useEffect(() => {
        onCardStateChange?.(saveButtonEnabled);
    }, [onCardStateChange, saveButtonEnabled]);

    useEffect(() => {
        if (!currentGenusValue) {
            refreshGenusOptions('');
        }
        else if (!currentSpeciesValue) {
            refreshSpeciesOptions('');
        }
    }, [currentGenusValue, currentSpeciesValue]);

    useEffect(() => {
        setGenusValue(currentGenusValue);
        setGenusOptions(currentGenusValue ? [currentGenusValue] : []);
        refreshGenusOptions(currentGenusValue?.label ?? '');
    }, [currentGenusValue]);

    useEffect(() => {
        setSpeciesValue(currentSpeciesValue);
        setSpeciesOptions(currentSpeciesValue ? [currentSpeciesValue] : []);
        refreshSpeciesOptions(currentSpeciesValue?.label ?? '');
    }, [currentSpeciesValue]);

    return <>
        <Card title='Genus & Species' icon={<Leaf color={muiTheme.palette.text.primary} />}>
            <Autocomplete
                label={'Genus'}
                highlightValue={shouldLockGenus}
                disabled={shouldLockGenus}
                options={genusOptions}
                value={genusValue}
                onInputChange={(_, value) => refreshGenusOptionsDebounced(value)}
                onChange={(_, value) => handleGenusChange(value)}
                onBlur={handleGenusBlur}
                inputRef={genusInputRef}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            <br />
            <Autocomplete
                label={'Species'}
                highlightValue={props.disabled}
                disabled={shouldLockSpecies}
                value={speciesValue}
                options={speciesOptions}
                onInputChange={(_, value) => refreshSpeciesOptionsDebounced(value)}
                onChange={(_, value) => setSpeciesValue(value)}
                onBlur={handleSpeciesBlur}
                inputRef={speciesInputRef}
                filterOptions={(x) => x}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            <div style={{ marginTop: '16px', marginBottom: '8px', textAlign: 'center' }}>
                <Link href="https://powo.science.kew.org" target="_blank" rel="noopener">
                    Plants of the World Online Reference
                </Link>
            </div>
            <Button
                primary={true}
                label="Save"
                disabled={!saveButtonEnabled}
                onClick={handleSaveButtonClick}
                style={{
                    margin: '16px 0px 0px auto',
                    padding: '8px 16px',
                    fontWeight: 700,
                    fontSize: 13,
                    lineHeight: '20px',
                }}
            />
        </Card>
    </>
};

export default TreeClassificationGenusSpeciesCard;
