import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { Color, Group, MathUtils, Mesh, Vector3 } from 'three';
import ValidationAction from '../../../enums/ValidationAction';
import { convertValueBetweenRanges } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import HeightGizmo from '../Gizmos/HeightGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import ValidationControls from '../ValidationControls/ValidationControls';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import Tree from '../../../@types/Tree';
import LeanGizmo from '../Gizmos/LeanGizmo';

const MIN_MAX_ZOOM = {
  min: 20,
  max: 500,
};

type PerspectiveViewProps = {
  currentTree: Partial<Tree> | null;
};

const PerspectiveView = ({ currentTree }: PerspectiveViewProps) => {
  const {
    treeMetrics,
    visibility,
    pointCloudModule: {
      pointCloud,
      environmentPointCloud,
      isEnvironmentVisible,
      pointSize
    },
    section,
    validation,
    actions,
    helper
  } = useSemanticScreenContext();

  const sectionHeightHelper = useRef<Mesh>(null);
  const sceneRef = useRef<HTMLCanvasElement>(null);

  const scaledInitialZoom = useMemo(() => {
    const boundingDiameter = pointCloud?.geometry.boundingSphere?.radius
      ? pointCloud?.geometry.boundingSphere?.radius * 2
      : 1;
    const scaledZoom = convertValueBetweenRanges(
      { min: 0, max: boundingDiameter + 1 },
      { min: MIN_MAX_ZOOM.min, max: MIN_MAX_ZOOM.max },
      boundingDiameter
    );
    return MathUtils.clamp(scaledZoom, MIN_MAX_ZOOM.min, MIN_MAX_ZOOM.max) * 0.8;
  }, [pointCloud?.geometry.boundingSphere?.radius]);

  // Canopy height is a delta value subtracted from tree height
  // HightGizmo sets up an eventlistener to handle changes, and for efficiency it's only setup ONCE
  //   therefore onCanopyHeightChange becomes a closure, and it captures the lexical environment in which it was created.
  //   The actual values (tree object) are no longer available from the closure.
  //   To solve this issue as the tree's height is needed to calculate canopyHeight,
  //     with actualHeight it is made possible to keep up-to-date the height value
  const { onCanopyHeightChange, setActualHeight } = useMemo(() => {
    let actualHeight = treeMetrics.height;

    return {
      onCanopyHeightChange: function (z: number) {
        treeMetrics.setCanopyHeight(actualHeight - z);
      },
      setActualHeight: function (newHeight: number) {
        actualHeight = newHeight;
      },
    };
  }, []);

  useEffect(() => {
    setActualHeight(treeMetrics.height);
  }, [treeMetrics.height, setActualHeight]);

  const localHeight = currentTree?.location_local ? currentTree.location_local[2] : 0;

  // Same logic for FirstBifurcationHeight as for Canopy height
  const { onFirstBifurcationHeightChange, setStateForFirstBifurcationHeightUpdate } = useMemo(() => {
    let actualBifurcationPoint = treeMetrics.firstBifurcationPoint;
    let actualViewerPositionHeight = helper.viewerPosition.z;

    function onFirstBifurcationHeightChange(value: number) {
      const correctedHeight = actualBifurcationPoint.z - actualViewerPositionHeight - localHeight;
      const correctedTarget = new Vector3(0,0,correctedHeight);

      const newFirstBifurcationPoint = actualBifurcationPoint.clone();
      newFirstBifurcationPoint.z = value + localHeight;
      treeMetrics.setFirstBifurcationPoint(newFirstBifurcationPoint);
      section.setDefaultTarget(correctedTarget);
      section.setTarget(correctedTarget);
      section.setDefaultNormal(new Vector3(0, 0, 1));
      section.setNormal(new Vector3(0, 0, 1));
    }

    return {
      onFirstBifurcationHeightChange,
      setStateForFirstBifurcationHeightUpdate: function (bifurcationPoint: Vector3, viewerHeight: number) {
        actualBifurcationPoint = bifurcationPoint;
        actualViewerPositionHeight = viewerHeight;
      },
    };
  }, []);

  useEffect(() => {
    setStateForFirstBifurcationHeightUpdate(treeMetrics.firstBifurcationPoint, helper.viewerPosition.z);
  }, [treeMetrics.firstBifurcationPoint, helper.viewerPosition.z]);

  const GroupElement = () => {
/*    const groupRef = useRef<Group>(null);

     const { camera, scene } = useThree();

    useEffect(() => {
      if (!groupRef?.current || !camera) return;

      const bottomPosition = (camera as OrthographicCamera).top + (camera as OrthographicCamera).bottom - treeMetrics.height;

      console.log("bottomPosition: ", bottomPosition)

      groupRef.current.position.set(0, 0, bottomPosition);
    }, [camera]); */

    return null;
  };

  return (
    <>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        gl={{ sortObjects: true }}
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledInitialZoom,
        }}
        ref={sceneRef}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

        <Suspense>
          <group position={new Vector3()}>
            <TreePointCloud
              treePointCloud={pointCloud}
              envPointCloud={environmentPointCloud}
              isEnvVisible={isEnvironmentVisible}
              pointSize={pointSize}
              locationDelta={helper.locationDelta}
            />

            <DragPoint
              name={'tree-location-point'}
              initialPosition={new Vector3()}
              color={new Color('red')}
              draggable={false}
              visibility={true}
              handleSize={0.1}
            />

            <HeightGizmo
              name={ValidationAction.Height}
              color={SemanticActionsColorMap[ValidationAction.Height]}
              positionZ={treeMetrics.height ?? 0}
              visible={visibility.height}
              draggable={!helper.isEditingDisabled && !validation.isTreeHeightCompleted}
              viewerPosition={helper.locationDelta}
              activeTool={helper.activeTool as string}
              grabbed={helper.draggedTool}
              setGrabbed={(name) => {
                if (helper.draggedTool) return;

                actions.setActiveTool(ValidationAction.Height);
                actions.setDraggedTool(name as ValidationAction);
              }}
              onChange={(z: number) => treeMetrics.setHeight(z)}
            />
            <HeightGizmo
              allowBelowZeroZ={true}
              name={ValidationAction.CanopyHeight}
              color={SemanticActionsColorMap[ValidationAction.CanopyHeight]}
              positionZ={treeMetrics.height - treeMetrics.canopyHeight}
              visible={visibility.canopyHeight}
              draggable={!helper.isEditingDisabled && !validation.isCrownHeightCompleted}
              activeTool={helper.activeTool as string}
              viewerPosition={helper.locationDelta}
              grabbed={helper.draggedTool}
              setGrabbed={(name) => {
                if (helper.draggedTool) return;

                actions.setActiveTool(name as ValidationAction);
                actions.setDraggedTool(name as ValidationAction);
              }}
              onChange={onCanopyHeightChange}
            />
            <HeightGizmo
              name={ValidationAction.FirstBifurcationPoint}
              color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
              positionZ={treeMetrics.firstBifurcationPoint.z - localHeight || 0}
              activeTool={helper.activeTool as string}
              visible={visibility.firstBifurcationPoint}
              draggable={!helper.isEditingDisabled && !validation.isFirstBifurcationCompleted}
              viewerPosition={helper.locationDelta}
              grabbed={helper.draggedTool}
              setGrabbed={(name) => {
                if (helper.draggedTool) return;

                if (name && helper.activeTool !== ValidationAction.FirstBifurcationPoint) {
                  actions.setActiveTool(name as ValidationAction);
                  section.setTarget(section.defaultTarget!);
                  section.setNormal(section.defaultNormal!);
                }

                actions.setDraggedTool(name as ValidationAction);
              }}
              onChange={onFirstBifurcationHeightChange}
            />
            <HeightGizmo
              ref={sectionHeightHelper}
              name={'section-height-helper'}
              color='#FFFFFF'
              positionZ={section.target?.z ?? 0}
              draggable={false}
              label={'Section view height'}
              depth={section.depth}
              visible={section.visibility}
              viewerPosition={helper.locationDelta}
            />
            <LeanGizmo handleSize={0.1} />
          </group>
        </Suspense>

        <ValidationControls
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
          minZoom={MIN_MAX_ZOOM.min}
          maxZoom={MIN_MAX_ZOOM.max}
          name='height'
        />
      </Canvas>
    </>
  );
};

export default PerspectiveView;
