import * as THREE from "three";

import Number3 from "../@types/Number3.js";
import { colorHexStringToRgbArray } from './pointCloudUtils'
import PointClassStyles from "../@types/PointClassStyles.js";
import TreePointCloud from "../@types/TreePointCloud.js";
const { LASFile } = require('./laslaz.js');

type Point = {
  position: Number3;
  intensity: number;
  classification: number;
};

type LASHeader = {
  pointsOffset: number;
  pointsFormatId: number;
  pointsStructSize: number;
  pointsCount: number;
  scale: Number3;
  offset: Number3;
  maxs: Number3;
  mins: Number3;
};

enum ColorClassifications {
  Canopy = "canopy",
  Trunk = "trunk",
  OtherTrunk = "otherTrunk",
  OtherCanopy = "otherCanopy",
  Environment = "environment",
  Branch = "branch",
  OtherBranch = "otherBranch"
};

export class PointCloud {
  public classificationColor = false;

  constructor(
    public key: string,
    public points: any, // function or array
    public pointCount: number,
    public scale: Number3,
    public offset: Number3,
    public mins: Number3,
    public maxs: Number3,
    public filterPoints: any = undefined
  ) {}
}

type GeometryProcessorConfig = {
  loadUnclassified?: any;
  corrective?: any;
  pointsMaterial?: {
    size?: number,
  }
};

class GeometryProcessor {
  public config: GeometryProcessorConfig;
  private mx: THREE.Vector3 | null;
  private mn: THREE.Vector3 | null;
  private z_max: number | null;
  private z_min: number | null;

  constructor() {
    this.mx = null;
    this.mn = null;
    this.z_max = null;
    this.z_min = null;
    this.config = {};
  }

  getConfig() {
    return this.config;
  }

  getColorClassification({ classification }: Point): ColorClassifications | null {
    return null;
  }

  static gradient: [number, Number3][] = [
    [0, [0, 0, 255]],
    [0.25, [0, 255, 0]],
    [0.5, [255, 255, 0]],
    [0.75, [255, 0, 0]],
    [1, [255, 255, 255]],
  ];

  static pickHex(color1: Number3, color2: Number3, weight: number) {
    const w1 = weight;
    const w2 = 1 - w1;
    const rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2) / 255.0,
      Math.round(color1[1] * w1 + color2[1] * w2) / 255.0,
      Math.round(color1[2] * w1 + color2[2] * w2) / 255.0,
    ];
    return rgb;
  }

  processGeometry(pointCloud: PointCloud) {
    // this.pointClouds.set(pointCloud.key, pointCloud);
    const geometry = new THREE.BufferGeometry();
    let count = pointCloud.pointCount;
    let classified = false;

    if (
      typeof this.config.loadUnclassified !== "undefined" &&
      !this.config.loadUnclassified
    ) {
      let classifiedCount = 0;
      for (let i = 0; i < count; i++) {
        let p = null;
        if (typeof pointCloud.points === "function") {
          p = pointCloud.points(i);
        } else {
          p = pointCloud.points[i];
        }

        const colorClass = this.getColorClassification(p);
        if (colorClass) {
          classifiedCount++;
          classified = true;
        }
      }
      count = classifiedCount;
      if (!classified) {
        count = pointCloud.pointCount;
      }
    }

    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);
    const intensity = new Float32Array(count);
    const classification = new Float32Array(count);

    const corrective = new THREE.Vector3(
      pointCloud.mins[0] /* - Math.abs(pointCloud.mins[0] - pointCloud.maxs[0]) */,
      pointCloud.mins[1] /* - Math.abs(pointCloud.mins[1] - pointCloud.maxs[1]) */,
      pointCloud.mins[2] /* - Math.abs(pointCloud.mins[2] - pointCloud.maxs[2]) */
    );

    for (let pointIndex = 0; pointIndex < pointCloud.pointCount; pointIndex++) {
      let p: Point;
      if (typeof pointCloud.points === "function") {
        p = pointCloud.points(pointIndex);
      } else {
        p = pointCloud.points[pointIndex];
      }
      if (this.getColorClassification(p)) {
        // find the first point with classification, so points without classification won't be colored by intensity
        pointCloud.classificationColor = true;
        break;
      }
    }

    let i = 0;
    for (let pointIndex = 0; pointIndex < pointCloud.pointCount; pointIndex++) {
      let p: Point;
      if (typeof pointCloud.points === "function") {
        p = pointCloud.points(pointIndex);
      } else {
        p = pointCloud.points[pointIndex];
      }
      const colorClass = this.getColorClassification(p);
      if (
        typeof this.config.loadUnclassified !== "undefined" &&
        !this.config.loadUnclassified &&
        classified &&
        colorClass
      ) {
        continue;
      }

      let x = p.position[0] * pointCloud.scale[0] + pointCloud.offset[0];
      let y = p.position[1] * pointCloud.scale[1] + pointCloud.offset[1];
      let z = p.position[2] * pointCloud.scale[2] + pointCloud.offset[2];

      if (this.mx === null) {
        this.mx = new THREE.Vector3(x, y, z);
      } else {
        this.mx.set(
          Math.max(this.mx.x, x),
          Math.max(this.mx.y, y),
          Math.max(this.mx.z, z)
        );
      }

      if (this.mn === null) {
        this.mn = new THREE.Vector3(x, y, z);
      } else {
        this.mn.set(
          Math.min(this.mn.x, x),
          Math.min(this.mn.y, y),
          Math.min(this.mn.z, z)
        );
      }

      let color;
      if (pointCloud.classificationColor) {
        color = this.getColor(colorClass, pointCloud);
      } else {
        const index = GeometryProcessor.gradient.findIndex(
          ([rangeStart]) => p.intensity / 65536 < rangeStart
        );
        const minColor = GeometryProcessor.gradient[index - 1];
        const maxColor = GeometryProcessor.gradient[index];
        color = GeometryProcessor.pickHex(
          minColor[1],
          maxColor[1],
          1 - (p.intensity / 65535 - minColor[0]) / (maxColor[0] - minColor[0])
        );
      }

      if (this.config.corrective) {
        positions[3 * i] = x - corrective.x;
        positions[3 * i + 1] = y - corrective.y;
        positions[3 * i + 2] = z - corrective.z;
      } else {
        positions[3 * i] = x;
        positions[3 * i + 1] = y;
        positions[3 * i + 2] = z;
      }

      if (colorClass) {
        this.z_max =
          this.z_max === null
            ? positions[3 * i + 2]
            : Math.max(this.z_max, positions[3 * i + 2]);
        this.z_min =
          this.z_min === null
            ? positions[3 * i + 2]
            : Math.min(this.z_min, positions[3 * i + 2]);
      }

      colors[3 * i] = color[0];
      colors[3 * i + 1] = color[1];
      colors[3 * i + 2] = color[2];

      intensity[i] = p.intensity;
      classification[i] = p.classification;

      i++;
    }

    if (positions.length > 0) {
      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
    }
    if (colors.length > 0) {
      geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    }
    if (intensity.length > 0) {
      geometry.setAttribute(
        "intensity",
        new THREE.BufferAttribute(intensity, 1)
      );
    }
    if (positions.length > 0) {
      geometry.setAttribute(
        "visibility",
        new THREE.BufferAttribute(new Float32Array(i).fill(1), 1)
      );
    }
    if (classification.length > 0) {
      geometry.setAttribute(
        "classification",
        new THREE.BufferAttribute(classification, 1)
      );
    }
    geometry.computeBoundingSphere();
    geometry.computeBoundingBox();

    return geometry;
  }

  getColor(colorClass: ColorClassifications | null, pointCloud: PointCloud) {
    const getNormalisedColor = (r: number, g: number, b: number) => [r/255.0, g/255.0, b/255.0];
    let rgb = getNormalisedColor(100, 100, 100);

    switch(colorClass) {
      case ColorClassifications.Canopy:
        rgb = getNormalisedColor(72, 187, 120);
        break;
      case ColorClassifications.Branch:
        rgb = getNormalisedColor(121, 85, 72);
        break;
      case ColorClassifications.OtherCanopy:
        rgb = getNormalisedColor(93, 186, 132);
        break;
      case ColorClassifications.OtherBranch:
        rgb = getNormalisedColor(120, 93, 84);
        break;
      case ColorClassifications.Environment:
        rgb = getNormalisedColor(100, 100, 100);
    }

    return rgb;
  }

  setConfig(config: {[key: string]: any;}) {
    this.config = {
      ...this.config,
      ...config,
    };
  }
}

class LASProcessor extends GeometryProcessor {
  private worker: Worker;

  constructor(
    private loader: THREE.Loader,
    private treeLocalCoordinates: any // boolean or array
  ) {
    super();
    this.config = {
      ...super.getConfig(),
      corrective: true,
      loadUnclassified: true,
      pointsMaterial: {
        size: 0.05,
      },
    };

    this.treeLocalCoordinates = treeLocalCoordinates;
    this.worker = new Worker("/vendor/workers/laz-loader-worker.js");
  }

  /**
   * Merge config for material and color classification
   *
   * @param {*} config The configuration object
   */
  setConfig(config: {[key: string]: any;}) {
    super.setConfig(config);
    this.config = {
      ...this.config,
      ...config,
    };
  }

  parse(data: any, path: string, onLoad: (pc: TreePointCloud) => void, onError: (e: any) => void, skip = 1) {
    let lasFile = new LASFile(data, this.worker);
    let lasHeader: LASHeader;
    return lasFile
      .open()
      .then(() => {
        lasFile.isOpen = true;
        return lasFile;
      })
      .then((lasFile: { getHeader: () => Promise<LASHeader>}) => { // after laslaz.js is refactored from using prototypes, lasFile's type could be LASLoader
        return lasFile.getHeader().then((h: LASHeader) => {
          return [lasFile, h];
        });
      })
      .then((v: [any, LASHeader]) => {
        lasFile = v[0];
        lasHeader = v[1];

        if (!lasHeader) {
          throw new Error("las header is undefined");
        }

        return lasFile.readData(
          lasHeader.pointsCount,
          0,
          skip,
          this.treeLocalCoordinates
        );
      })
      .then(({ buffer, count }: { buffer: any, count: number }) => {
        if (this.treeLocalCoordinates && count < 2000) {
          throw new Error("Not enough points");
        }

        const Unpacker = lasFile.getUnpacker();
        const decoder = new Unpacker(buffer, count, lasHeader);

        const pcAndMesh = this.processLAS(decoder);

        onLoad(pcAndMesh as unknown as TreePointCloud);
      })
      .catch((err: any) => onError(err))
      .finally(() => {
        lasFile.close();
      });
  }

  getColorClassification({ classification }: Point) {
    if (classification === 21) {
      return ColorClassifications.Canopy;
    } else if (classification === 20) {
      return ColorClassifications.Trunk;
    } else if (classification === 22) {
      return ColorClassifications.OtherTrunk;
    } else if (classification === 23) {
      return ColorClassifications.OtherCanopy;
    } /*else if (classification === 0) {
      return ColorClassifications.Environment;
    } */else {
      return null;
    }
  }

  public processLAS(lasBuffer: any) {
    const pc = new PointCloud(
      "pc",
      (i: number) => lasBuffer.getPoint(i),
      lasBuffer.pointsCount,
      lasBuffer.scale,
      lasBuffer.offset,
      lasBuffer.mins,
      lasBuffer.maxs
    );
    const geometry = this.processGeometry(pc);
    const three = THREE;
    const createMaterial = (THREE = three, size = 0.5, opacity = 1.0) => {
      const material = new THREE.PointsMaterial({ size, opacity });

      material.clipIntersection = false;
      material.vertexColors = true;

      material.userData.setSize = (newSize: number) => {
        material.size = newSize ? size * newSize : size;
        material.needsUpdate = true;
      };

      material.userData.defaultSize = size;

      // material.localClippingEnabled = true;       became deprecated

      material.userData.filterPoints = (coords: any) => {
        if (!coords) {
          return (material.clippingPlanes = []);
        }
        const { minZ, maxZ } = coords;
        material.clippingPlanes = [
          new THREE.Plane(new THREE.Vector3(0, 0, 1), -minZ),
          new THREE.Plane(new THREE.Vector3(0, 0, -1), maxZ),
        ];
      };

      material.onBeforeCompile = function (shader) {
        shader.vertexShader = `
          attribute float visibility;
          varying float vVisible;
        ${shader.vertexShader}`.replace(
          `gl_PointSize = size;`,
          `gl_PointSize = size;
            vVisible = visibility;
          `
        );
        shader.fragmentShader = `
          varying float vVisible;
        ${shader.fragmentShader}`.replace(
          `#include <clipping_planes_fragment>`,
          `
            if (vVisible < 0.5) discard;
          #include <clipping_planes_fragment>`
        );
      };

      return material;
    };

    return {
      pc: pc,
      geometry: geometry,
      material: createMaterial(),
      createMaterial,
    };
  }
}

class LoaderBase extends THREE.Loader {
  public processor: LASProcessor | undefined;
  private config: {[key: string]: any;} = {};

  constructor(
    public manager: THREE.LoadingManager,
    private responseType: "arraybuffer"
  ) {
    super(manager);
  }

  /**
   * Set GeometryProcessor accessor method
   *
   * @param {*} processor The geometry processor instance
   */
  setProcessor(processor: LASProcessor) {
    this.processor = processor;
  }

  /**
   * Get GeometryProcessor accessor method
   *
   * @returns The geometry processor instance
   */
  getProcessor() {
    return this.processor;
  }

  isInProgress = false;
  callAfterDone: (() => void)[] = [];

  load(
    url: string,
    onLoad: (pc: TreePointCloud) => void,
    onProgress?: any,
    onError?: (e: any) => void,
    skip = 1,
    isCalledFromCallback = false
  ) {
    if (this.isInProgress && !isCalledFromCallback) {
      this.callAfterDone.push(() =>
        this.load(url, onLoad, onProgress, onError, skip, true)
      );
      return;
    }

    let resourcePath: string;

    this.isInProgress = true;

    if (this.resourcePath !== "") {
      resourcePath = this.resourcePath;
    } else if (this.path !== "") {
      resourcePath = this.path;
    } else {
      resourcePath = THREE.LoaderUtils.extractUrlBase(url);
    }

    // Tells the LoadingManager to track an extra item, which resolves after
    // the model is fully loaded. This means the count of items loaded will
    // be incorrect, but ensures manager.onLoad() does not fire early.
    this.manager.itemStart(url);

    const _onError = (e: any) => {
      if (onError) {
        onError(e);
      } else {
        console.error(e);
      }

      this.manager.itemError(url);
      this.manager.itemEnd(url);

      if (this.callAfterDone.length) {
        setTimeout(() => {
          const func = this.callAfterDone.pop();
          if (func) func();
        }, 0);
      } else {
        this.isInProgress = false;
      }
    };

    const loader = new THREE.FileLoader(this.manager);

    loader.setPath(this.path);
    loader.setResponseType(this.responseType);
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);

    loader.load(
      url,
      (data) => {
        try {
          this.processor?.parse(
            data,
            resourcePath,
            (result) => {
              onLoad(result);
              this.manager.itemEnd(url);

              if (this.callAfterDone.length) {
                setTimeout(() => {
                  const func = this.callAfterDone.pop();
                  if (func) func();
                }, 0);
              } else {
                this.isInProgress = false;
              }
            },
            _onError,
            skip
          );
        } catch (e) {
          _onError(e);
        }
      },
      onProgress,
      _onError
    );
  }

  setConfig(config: {[key: string]: any;}) {
    this.config = {
      ...this.config,
      config,
    };
  }
}

export default class LASLoader extends LoaderBase {
  constructor(manager: THREE.LoadingManager, treeLocalCoordinates = false) {
    super(manager, "arraybuffer");
    this.setProcessor(new LASProcessor(this, treeLocalCoordinates));
    this.manager = manager;
  }
}
