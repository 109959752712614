import proj4 from 'proj4';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Icon, Panorama } from '..';
import { LoaderWrapper } from '../../layout';
import { getPointLabelDefaultHexColor, useConfig } from '../../providers/config';
import { getCapturePoint } from '../../providers/data';
import { RECLASS_TOOLS } from '../../routes/Validation/SegmentationValidation';
import { Button, Color, DropdownInput, Slider, Toggle } from '../inputs';
import ClassOption, { ClassOptionConfig } from './ClassOption';
import SemanticMover, { VIEW } from './SemanticMover';
import { useUser } from '../../providers/user';
import ValidationTool from '../../enums/ValidationTool';
import ClickableBadge from './ClickableBadge';
import ViewWrapper from './Views/ViewWrapper';
import { PointCloudViewType } from '../../enums/PointCloudViewType';
import ValidationAction from '../../enums/ValidationAction';
import TreeFlowStatus from '../../enums/TreeFlowStatus';
import { useSegmentationScreenContext } from '../../hooks/betaHooks/segmentation-screen-context';
import { useTheme } from '../../providers/theme';
import { useModal } from '../../providers/modal';

/* const generateUrl = (id) =>
  `${window.location.pathname.split("/").slice(0, 3).join("/")}/location/${id}`; */
const SHOWN_FIRST_BIFURCATION_TOAST = 'hasShownFirstBifurcationToast';

export const exactFirstBifurcationRangeOffset = 0.2;

/**
 * @typedef {Object} ExactFirstBifurcationUpdatePayload
 * @property {number | undefined} min
 * @property {number | undefined} max
 * @property {number | undefined} x
 * @property {number | undefined} y
 * @property {number | undefined} z
 */

const SegmentationEditor = ({
  treeLoaded = false,
  loading,
  currentTree,
  selection,
  onTreeAdd,
  environmentToggleProps,
  selectTree,
  layerSources,
  saveLAZ,
  // rest of the props currently passed down as is
  setRotationHandler,
  updateGeometry,
}) => {
  const {
    helper: { toasts, errorTool, errorPoints, showToast, lockView, editing, setEditing, pointCloudCorrectedPosition, setActiveTool, viewerPosition, setPanoramicLoading },
    reclass: { actionMode, reclassStates, reclassTool, changeReclassTool, brushRadius, setBrushRadius, reclassPoints, setReclassPoints },
    error: { changeErrorTool, addErrorPoint, handleSaveError, handleCancelError, handleErrorUndo },
    pointCloudModule: {
      pointCloud: pointcloud,
      environmentPointCloud,
      isEnvironmentVisible,
    },
    section: {
      setTarget: setSectionTarget,
      setNormal: setSectionNormal,
      setDefaultDepth
    },
    treeMetrics
  } = useSegmentationScreenContext();
  const { isDark } = useTheme();
  const background = isDark ? 0x000000 : 0xffffff;
  const { presentModal, dismissModal } = useModal();

  const hasSeenFirstBifurcationToast = sessionStorage.getItem(SHOWN_FIRST_BIFURCATION_TOAST) === 'true';
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const view = searchParams.get('view') ?? VIEW.DEFAULT;
  const { getConfig } = useConfig();
  const [isPanoramic, setIsPanoramic] = useState(true);
  const [capturePoint, setCapturePoint] = useState({
    position: { coordinates: [0, 0, 0] },
  });
  const [localPosition, setPosition] = useState({
    hasChanged: false,
    coords: [],
  });
  const [colors, setColors] = useState({
    canopy: getPointLabelDefaultHexColor('canopy'),
    trunk: getPointLabelDefaultHexColor('trunk'),
  });

  const [opacities, setOpacities] = useState({ canopy: 1, trunk: 1 });

  const [pointSize, setPointSize] = useState(0.5);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [activeEditor, setActiveEditor] = useState('');
  const [reclassifySource, setReclassifySource] = useState(20);
  const [reclassifyTarget, setReclassifyTarget] = useState(21);
  const [showMeasurementEllipse, setShowMeasurementEllipse] = useState(false);

  const [selectedTrunk, setSelectedTrunk] = useState((treeMetrics?.trunks && treeMetrics?.trunks[0]) ?? null);

  const { token } = useUser();

  const viewPanorama = useCallback(async () => {
    const panoramaImages = await getCapturePoint(currentTree, token);

    setCapturePoint({
      position: { coordinates: panoramaImages?.[0]?.origin.coordinates },
    });

    const location = JSON.parse(currentTree?.location)?.coordinates.slice(0);
    let position = proj4('EPSG:4326', 'localProjection', location || []);

    if (currentTree.location_local !== undefined) {
      position = currentTree.location_local;
    }

    setPosition({ coords: position });
  }, [currentTree?.id]);

  useEffect(() => {
    if (!currentTree?.id && loading) return;
    setActiveTool(null);
    setShowMeasurementEllipse(false);
    setSelectedTrunk((treeMetrics.trunks && treeMetrics.trunks[0]) ?? null);
  }, [currentTree?.id]);

  useEffect(() => {
    if (!isPanoramic) return;
    if (currentTree?.id) viewPanorama();
  }, [viewPanorama, currentTree?.id, isPanoramic]);

  const reclassSaveDisabled = useMemo(
    () => actionMode !== ValidationTool.Reclassify || !reclassStates.length,
    [actionMode, reclassStates.length]
  );

  /*   const addTree = useCallback(
    async (e) => {
      const managedArea = selection[0];
      const newTree = await onTreeAdd(e, managedArea);
      history.push(generateUrl("new/" + newTree?.id));
    },
    [history, onTreeAdd, selection]
  ); */

  const onColorChange = ({ name, value }) => {
    setColors((old) => {
      const newColors = Object.assign({}, old);
      newColors[name] = value;

      localStorage.setItem('pointcloud-colors', JSON.stringify(newColors));

      return newColors;
    });
  };

  const onColorVisibilityChange = ({ name, value }) => {
    setOpacities((old) => {
      const newOpacities = Object.assign({}, old);
      newOpacities[name] = value ? 1 : 0;

      localStorage.setItem('pointcloud-opacities', JSON.stringify(newOpacities));

      return newOpacities;
    });
  };

  /*   const onFirstBifurcationViewChange = ({ value }) => {
    const updatedSearchParams = new URLSearchParams(window.location.search);
    updatedSearchParams.set(
      "view",
      value ? VIEW.FIRST_BIFURCATION : VIEW.DEFAULT
    );
    history.push(
      `${window.location.pathname}?${updatedSearchParams.toString()}`
    );
  }; */

  useEffect(() => {
    if (hasSeenFirstBifurcationToast || view !== VIEW.FIRST_BIFURCATION) return;
    sessionStorage.setItem(SHOWN_FIRST_BIFURCATION_TOAST, 'true');
  }, [hasSeenFirstBifurcationToast, view]);

  const setEllipse = (ellipse) => {
    // treeMetrics.setGirth(null);
    setTimeout(() => {
      if (!ellipse) return;

      setSectionTarget({ x: viewerPosition.x + ellipse.dX, y: viewerPosition.y + ellipse.dY, z: viewerPosition.z + ellipse.height });
      setSectionNormal(ellipse?.normal);
      // treeMetrics.setGirth(ellipse);
    }, 1);
  };

  const setSelectedTrunkAndFirstGirth = (trunk) => {
    setSelectedTrunk(trunk ? trunk : null);
    if (!trunk?.girths.length) {
      setSectionTarget({ x: viewerPosition.x, y: viewerPosition.y, z: viewerPosition.z + 1 });
      setSectionNormal({ x: 0, y: 0, z: 1 });
    } else {
      setEllipse(trunk ? trunk.girths[0] : null);
    }
  };

  const toggleMeasurementEllipse = () => {
    if (!showMeasurementEllipse) {
      setDefaultDepth();
      setActiveTool(ValidationAction.Girth);
    } else {
      setActiveTool(null);
    }
    setShowMeasurementEllipse((prevState) => !prevState);
  };

  // --- minimap handling
    const mapRef = useRef(null);

    const maLayer = {
      id: 'mas',
      source: 'mas',
      type: 'fill',
      color: '#082',
      opacity: 0.32,
    };
  
    const treeLayers = [
      {
        id: TreeFlowStatus.SegmentationValidationQueued,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SegmentationValidationQueued],
        color: '#ff0000',
      },
      {
        id: TreeFlowStatus.SegmentationValidationDone,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SegmentationValidationDone],
        color: '#AFFF14',
      },
      {
        id: TreeFlowStatus.SegmentationValidationDeleted,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SegmentationValidationDeleted],
        color: '#666',
      },
      {
        id: TreeFlowStatus.SentToField,
        source: 'trees',
        type: 'circle',
        filter: ['==', ['get', 'status'], TreeFlowStatus.SentToField],
        color: '#ffff00',
      },
    ].map((layer) => {
      const config = getConfig(`statuses.${layer.id}`);
      return {
        ...layer,
        onClick: (centre, feature, id) => {
          selectTree(feature.id);
        },
        label: config?.label || 'Unnamed',
        color: getConfig(`colors.${config?.color}`) || '#08f',
      };
    });
  
    const layers = [maLayer, ...treeLayers];

  // --- minimap handling

  return (
    <LoaderWrapper loading={loading}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        {view === VIEW.FIRST_BIFURCATION && !hasSeenFirstBifurcationToast && (
          <div className='toast'>Click anywhere to place the first bifurcation point</div>
        )}

        {Boolean(toasts.length) && (
          <div className='toast-wrapper'>
            {toasts.map((toast, index) => (
              <div key={index} className={`toast ${toast.type} ${toasts.length - 1 === index ? 'last' : ''}`}>
                {toast.msg}
              </div>
            ))}
          </div>
        )}

        {(pointcloud || pointcloud === false) && (
          <Fragment>
            {actionMode === ValidationTool.Inspection_View && (
              <div className='semantic-upper-toolbar inspection-view'>
                <span>
                  <b>Inspection View</b>
                </span>
              </div>
            )}
            {actionMode === ValidationTool.Reclassify && (
              <div className='semantic-upper-toolbar reclassify'>
                <div className='tools-wrapper'>
                  <span>Tool</span>
                  <ClickableBadge
                    icon={'paint-brush'}
                    activeColor={'green'}
                    selected={reclassTool === RECLASS_TOOLS.BRUSH}
                    onClick={() => changeReclassTool(RECLASS_TOOLS.BRUSH)}
                  />
                  <ClickableBadge
                    icon={'vector-square'}
                    activeColor={'green'}
                    selected={reclassTool === RECLASS_TOOLS.POLYGON}
                    onClick={() => changeReclassTool(RECLASS_TOOLS.POLYGON)}
                  />
                </div>
                <span>Source class</span>
                <DropdownInput
                  options={ClassOptionConfig}
                  value={reclassifySource}
                  onPureChange={setReclassifySource}
                  optionComponent={ClassOption}
                  small
                />
                <span>Target class</span>
                <DropdownInput
                  options={ClassOptionConfig.filter((o) => o.value !== reclassifySource)}
                  value={reclassifyTarget}
                  onPureChange={setReclassifyTarget}
                  optionComponent={ClassOption}
                  small
                />
                {reclassTool === RECLASS_TOOLS.BRUSH && (
                  <div className='brush-size-input-wrapper'>
                    <span>Brush</span>
                    <Slider
                      valueFormatter={(val) => val?.toFixed(0) ?? '-'}
                      label='Brush size'
                      value={brushRadius}
                      onPureChange={setBrushRadius}
                      min={12}
                      max={60}
                      step={12}
                    />
                  </div>
                )}
                <Button label='Save changes' disabled={reclassSaveDisabled} onClick={saveLAZ} />
              </div>
            )}
            <div className='semantic-panorama-toggle'>
              <Toggle {...environmentToggleProps} />
              <Toggle value={isPanoramic} onPureChange={() => setIsPanoramic((old) => !old)} label='Toggle Panorama' />
            </div>
            <div className='segmentation-views-container'>
              <SemanticMover
                useMouseLeftButtonForEdit                                                                             // REALLY USED
                view={view}
                tree={currentTree}
                // Not needed
                pointcloud={pointcloud}
                actionMode={actionMode}                                                                               // REALLY USED
                editorName='height' // Will become obsolete, panels will be identified via ID                         // REALLY USED
                // -> Moved to ValidationSlice
                pointSize={pointSize}
                colors={colors}
                opacities={opacities}
                azimuthAngle={azimuthAngle}
                setAzimuthAngle={setAzimuthAngle}                                                                     // REALLY USED
                setActiveEditor={setActiveEditor} // Renamed to setActivePanel, will use ID instead of name           // REALLY USED
                activeEditor={activeEditor} // Renamed to activePanelId, will use numerical ID instead of name
                // -> Moved to ReclassSlice
                brushRadius={brushRadius}
                reclassifySource={reclassifySource}
                reclassifyTarget={reclassifyTarget}
                reclassTool={reclassTool}
                // Not needed, will be rewrote
                firstBifurcationDelta={treeMetrics.firstBifurcationDelta}                                             // REALLY USED
                position={pointCloudCorrectedPosition}                                                                // REALLY USED
                // setPosition={changePosition}                                                                       // NOT USED
                exactFirstBifurcation={treeMetrics.exactFirstBifurcation}
                onExactFirstBifurcationChange={treeMetrics.onExactFirstBifurcationChange}
                // rest of the props currently passed down, below
                getConfig={getConfig}                                                                                 // REALLY USED
                isDark={isDark}                                                                                       // REALLY USED
                background={background}                                                                               // REALLY USED
                setRotationHandler={setRotationHandler}
                editing={editing}                                                                                     // REALLY USED
                setEditing={setEditing}                                                                               // REALLY USED
                height={treeMetrics.height}
                onHeightChange={treeMetrics.heightHandler}
                onTrunkHeightChange={treeMetrics.trunkHeightHandler}
                canopyHeight={treeMetrics.canopyHeight}
                onCanopyHeightChange={treeMetrics.canopyHeightHandler}
                canopy={treeMetrics.canopy}
                onCanopyChange={treeMetrics.canopyHandler}
                girth1={treeMetrics.girth1}
                onGirth1Change={treeMetrics.girth1Handler}
                environmentPointcloud={environmentPointCloud}
                isEnvironmentVisible={isEnvironmentVisible}
                reclassPoints={reclassPoints}
                setReclassPoints={setReclassPoints}                                                             // REALLY USED
                updateGeometry={updateGeometry}
                errorTool={errorTool}
                changeErrorTool={changeErrorTool}
                errorPoints={errorPoints}
                addErrorPoint={addErrorPoint}
                saveError={handleSaveError}
                cancelError={handleCancelError}                                                                       // REALLY USED
                handleErrorUndo={handleErrorUndo}
                showToast={showToast}
                // isUp={false}                                                                                       // REALLY USED
                setBrushRadius={setBrushRadius}                                                                       // WAS NOT PASSED DOWN, BUT USED
                lockView={lockView}                                                                                   // WAS NOT PASSED DOWN, BUT USED
              />
              <div className='grid-right-side'>
                {isPanoramic ? (
                  <Panorama
                    onTreeChange={selectTree}
                    treeId={currentTree?.id}
                    managedAreaCode={selection?.[0]?.code}
                    optionalPosition={null}
                    enableAddTree={false}
                    setLoading={setPanoramicLoading}
                    mapSources={layerSources}
                    mapLayers={layers}  
                    enabledMiniMap={true}
                    mapRef={mapRef}
                    enableGoogleMapsButton={true}
                  />
                ) : (
                  <>
                    <SemanticMover
                      position={pointCloudCorrectedPosition}
                      // setPosition={changePosition}                                                             // NOT USED
                      pointcloud={pointcloud}
                      editing='canopy'
                      isUp
                      useMouseLeftButtonForEdit
                      colors={colors}
                      pointSize={pointSize}
                      azimuthAngle={azimuthAngle}
                      setAzimuthAngle={setAzimuthAngle}
                      activeEditor={activeEditor}
                      setActiveEditor={setActiveEditor}
                      editorName='canopy'
                      opacities={opacities}
                      view={view}
                      presentModal={presentModal}
                      dismissModal={dismissModal}
                      tree={currentTree}
                      actionMode={actionMode}
                      // rest of the props currently passed down
                      getConfig={getConfig}
                      isDark={isDark}
                      background={background}
                      setRotationHandler={setRotationHandler}
                      setEditing={setEditing}
                      height={treeMetrics.height}
                      onHeightChange={treeMetrics.heightHandler}
                      onTrunkHeightChange={treeMetrics.trunkHeightHandler}
                      canopyHeight={treeMetrics.canopyHeight}
                      onCanopyHeightChange={treeMetrics.canopyHeightHandler}
                      canopy={treeMetrics.canopy}
                      onCanopyChange={treeMetrics.canopyHandler}
                      girth1={treeMetrics.girth1}
                      onGirth1Change={treeMetrics.girth1Handler}
                      environmentPointcloud={environmentPointCloud}
                      isEnvironmentVisible={isEnvironmentVisible}
                      reclassPoints={reclassPoints}
                      setReclassPoints={setReclassPoints}
                      updateGeometry={updateGeometry}
                      errorTool={errorTool}
                      changeErrorTool={changeErrorTool}
                      errorPoints={errorPoints}
                      addErrorPoint={addErrorPoint}
                      saveError={handleSaveError}
                      cancelError={handleCancelError}
                      handleErrorUndo={handleErrorUndo}
                      showToast={showToast}
                      setBrushRadius={setBrushRadius}                                                          // WAS NOT PASSED DOWN, BUT USED
                      lockView={lockView}                                                                      // WAS NOT PASSED DOWN, BUT USED
                    />
                  </>
                )}
                {pointcloud && (
                  <div className='girth-section-container'>
                    <ViewWrapper
                      currentTree={currentTree}
                      hideTabSelector={true}
                      initialView={PointCloudViewType.SECTION}
                      disabled={currentTree?.tree_flow_status === TreeFlowStatus.MeasurementValidationQueued}
                      minZoom={40}
                      maxZoom={800}
                    />
                    <div className='measurement-ellipse-toggle'>
                      <Toggle value={showMeasurementEllipse} onPureChange={toggleMeasurementEllipse} label='Show Ellipse' />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
        <div className='semantic-pc-controls'>
          <div className='semantic-pc-colors'>
            <Color label='Canopy' name='canopy' onChange={onColorChange} value={colors.canopy} key='canopy' />
            <Toggle name='canopy' onChange={onColorVisibilityChange} label='' value={!!opacities.canopy} />
            <Color label='Trunk' name='trunk' onChange={onColorChange} value={colors.trunk} key='trunk' />
            <Toggle name='trunk' onChange={onColorVisibilityChange} label='' value={!!opacities.trunk} />
            <span>Point size</span>
            <Slider
              valueFormatter={(val) => val?.toFixed(2) ?? '-'}
              label='Point size'
              value={pointSize}
              onPureChange={setPointSize}
              min={0.5}
              max={3}
              step={0.5}
            />
          </div>
          {lockView && <Icon className='error-lock' icon='lock' />}
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default SegmentationEditor;
