import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Vector3 } from 'three';
import Ellipse from '../../@types/Ellipse';
import Number3 from '../../@types/Number3';
import Tree from '../../@types/Tree';
import { DEFAULT_ELLIPSE } from '../../components/SemanticsValidation/Cards/TrunkDetailsCard/Constants';
import { parseGirth } from '../../components/SemanticsValidation/ValidationUtils';
import { DEFAULT_SECTION_DEPTH } from '../../constants/sectionView';
import { useActionStack } from '../../core';
import ValidationAction from '../../enums/ValidationAction';
import ValidationTool from '../../enums/ValidationTool';
import { RECLASS_TOOLS } from '../../routes/Validation/SegmentationValidation';
import { Girth } from '../../store/TreeSlice';
import { getDefaultGirth } from './semantic-screen-context';
import { usePointCloud } from './use-point-cloud';

function getDefaultGirthForSemanticMover(): Ellipse {
  return {
    dX: 0,
    dY: 0,
    rX: 1,
    rY: 2,
    rotation: 0,
    diameter: null,
  }
}

function getDefaultCanopy(): Ellipse {
  return {
    dX: 0,
    dY: 0,
    rX: 1.8,
    rY: 2.2,
    rotation: 0.1,
    diameter: null,
  };
}

type ExactFirstBifurcation = {
  min: number,
  max: number,
  x: number,
  y: number,
  z: number,
}

function getDefaultExactFirstBifurcation(): ExactFirstBifurcation {
  return {
    min: 0.8,
    max: 1.2,
    x: 0,
    y: 0,
    z: 1,
  }
}

function getDefaultFirstBifurcation() {
  return `{"type":"Point","coordinates":[0,0,0],"isInitial":true}`;
}

export type EllipseWithNormalAndHeight = Ellipse & { normal: Vector3; height: number };

export enum ERROR_TOOLS {
  POINT = 'POINT',
  LINE = 'LINE',
  POLYGON = 'POLYGON',
}

type ToastType = 'info' | 'error' | 'warning';

type Toast = {
  msg: string;
  type: ToastType;
};

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>;
type RefType<T> = React.MutableRefObject<T | null>;

const indexToAxisMap: { [key: number]: string } = {
  0: 'x',
  1: 'y',
  2: 'z',
};

const exactFirstBifurcationRangeOffset = 0.2;

export interface SegmentationScreenContextValue {
  pointCloudModule: ReturnType<typeof usePointCloud>;
  section: {
    depth: number;
    lookAt: Vector3 | null;
    lookFrom: Vector3 | null;
    target: Vector3;
    normal: Vector3;
    defaultTarget: Vector3 | null;
    defaultNormal: Vector3 | null;
    visibility: boolean;
    setDepth: StateSetter<number>;
    setLookAt: StateSetter<Vector3 | null>;
    setLookFrom: StateSetter<Vector3 | null>;
    setTarget: StateSetter<Vector3>;
    setNormal: StateSetter<Vector3>;
    setDefaultTarget: StateSetter<Vector3 | null>;
    setDefaultNormal: StateSetter<Vector3 | null>;
    setDefaultDepth: () => void;
    resetSectionTargetToDefault: () => void;
    setVisibility: StateSetter<boolean>;
  };
  helper: {
    toasts: Toast[];
    showToast: (msg: string, type: ToastType) => void;
    locationDelta: Vector3;
    treeLocation: Vector3;
    pointCloudCorrectedPosition: Vector3;
    viewerPosition: Vector3;
    positionLocal: Number3;
    isEditingDisabled: boolean;
    activeTool: ValidationAction | null;
    setActiveTool: StateSetter<ValidationAction | null>;
    errorTool: ERROR_TOOLS;
    errorPoints: any[]; // CHECK
    setPanoramicLoading: StateSetter<boolean>;
    isLoading: boolean;
    editing: string;
    setEditing: StateSetter<string>;
    lockView: boolean;
    azimuthAngle: React.MutableRefObject<number>
  };
  trunkActions: {
    shownGirth: Girth;
    updateGirth: any;
    setShownGirth: StateSetter<Girth>;
  };
  treeMetrics: {
    height: number;
    canopyHeight: number;
    trunkHeight: number;
    firstBifurcationPoint: string;
    firstBifurcationCoordinates: Number3;
    firstBifurcationDelta: Vector3;
    exactFirstBifurcation: ExactFirstBifurcation;
    girth1: Ellipse;
    canopy: Ellipse;
    tmsCategory: string;

    heightHandler: (val: number) => void;
    canopyHeightHandler: (val: number) => void;
    trunkHeightHandler: (val: number) => void;
    firstBifurcationPointHandler: (val: string) => void;
    girth1Handler: (val: Ellipse) => void;
    canopyHandler: (val: Ellipse) => void;
    onExactFirstBifurcationChange: (payload: ExactFirstBifurcation) => void;
    setTmsCategory: StateSetter<string>;
  };
  visibility: {
    firstBifurcationPoint: boolean;
    girth: boolean;
  };
  actions: {
    canUndo: boolean;
    setAzimuthAngle: (angle: number) => number;
    _handleSkip: (direction?: number) => () => void;
  };
  validation: {
    isFirstBifurcationMidPointCompleted: boolean;
    isTrunkDetailsCompleted: boolean;
  };
  reclass: {
    actionMode: ValidationTool;
    reclassTool: string;
    brushRadius: number;
    reclassPoints: any[]; // CHECK
    reclassStates: any[]; // CHECK 
    setActionMode: StateSetter<ValidationTool>;
    changeReclassTool: (newTool: "BRUSH" | "POLYGON") => void;
    setBrushRadius: StateSetter<number>;
    setReclassPoints: StateSetter<any[]>; // CHECK
    setReclassStates: StateSetter<any[]>; // CHECK
  };
  error: {
    changeErrorTool: (newTool: ERROR_TOOLS) => void;
    addErrorPoint: (point: any) => void;
    handleSaveError: (comment: any, azimuthAngle: any, polarAngle: any, zoom: any, worldPos: any, worldDir: any) => Promise<void>;
    handleCancelError: () => void;
    handleErrorUndo: () => void;
  };
}

export interface SegmentationScreenContextProviderProps extends PropsWithChildren {
  //input props
  currentTree: Partial<Tree> | null;
  setCurrentIndex: StateSetter<number | null>;
  updateTree: (options: any) => Promise<any>;
  moveToNeighbour: (direction?: number) => {
    index: number;
    nextIndex: number;
    treeId: string | undefined;
  };
  dismissModal: (() => void) | undefined;
  saveError: (payload: any) => Promise<any>;
  loading: boolean;
  contextsLoadingState: boolean;
}

export const SegmentationScreenContext = createContext<SegmentationScreenContextValue>(null as any);

export const useSegmentationScreenContext = () => useContext(SegmentationScreenContext);

type ChangeHandlerActionTypes = 'SKIP' | 'UPDATE' | 'POSITION' | 'height' | 'canopyHeight' | 'trunkHeight' | 'girth1' | 'canopy' | 'firstBifurcationPoint';

export const SegmentationScreenContextProvider = ({
  children,
  currentTree,
  setCurrentIndex,
  updateTree,
  moveToNeighbour,
  dismissModal,
  saveError,
  loading,
  contextsLoadingState
}: SegmentationScreenContextProviderProps) => {

  // VALUES THAT ARE CHANGED BY THE USERS
  const [height, setHeight] = useState(3);
  const heightPrev = useRef<number | null>(null);
  const heightTimeout = useRef<NodeJS.Timeout | null>(null);
  const heightHandler = changeHandler(setHeight, heightPrev, heightTimeout, 'height');

  const [canopyHeight, setCanopyHeight] = useState(1);
  const canopyHeightPrev = useRef<number | null>(null);
  const canopyHeightTimeout = useRef<NodeJS.Timeout | null>(null);
  const canopyHeightHandler = changeHandler(setCanopyHeight, canopyHeightPrev, canopyHeightTimeout, 'canopyHeight');

  const [trunkHeight, setTrunkHeight] = useState(0.7);
  const trunkHeightPrev = useRef<number | null>(null);
  const trunkHeightTimeout = useRef<NodeJS.Timeout | null>(null);
  const trunkHeightHandler = changeHandler(setTrunkHeight, trunkHeightPrev, trunkHeightTimeout, 'trunkHeight');

  const [firstBifurcationPoint, setFirstBifurcationPoint] = useState(getDefaultFirstBifurcation());
  const firstBifurcationPointPrev = useRef<string | null>(null);
  const firstBifurcationPointTimeout = useRef<NodeJS.Timeout | null>(null);
  const firstBifurcationPointHandler = changeHandler(
    setFirstBifurcationPoint,
    firstBifurcationPointPrev,
    firstBifurcationPointTimeout,
    'firstBifurcationPoint'
  );

  const [girth1, setGirth1] = useState(getDefaultGirthForSemanticMover());
  const girth1Prev = useRef<Ellipse | null>(null);
  const girth1Timeout = useRef<NodeJS.Timeout | null>(null);
  const girth1Handler = changeHandler(setGirth1, girth1Prev, girth1Timeout, 'girth1');

  // SemanticMover use girth1Handler, could unify the logic
  const [shownGirth, setShownGirth] = useState<Girth>(getDefaultGirth());

  const [canopy, setCanopy] = useState(getDefaultCanopy());
  const canopyPrev = useRef(null);
  const canopyTimeout = useRef<NodeJS.Timeout | null>(null);
  const canopyHandler = changeHandler(setCanopy, canopyPrev, canopyTimeout, 'canopy');

  const [tmsCategory, setTmsCategory] = useState<string>('l0');

  const [exactFirstBifurcation, setExactFirstBifurcation] = useState(getDefaultExactFirstBifurcation());

  // ERROR STATES
  const [errorTool, setErrorTool] = useState(ERROR_TOOLS.POINT);
  const [errorPoints, setErrorPoints] = useState<any[]>([]);

  // HELPERS
  const [activeTool, setActiveTool] = useState<ValidationAction | null>(null);

  // POINTCLOUD STATES
  // const [viewerPosition, setViewerPosition] = useState(new Vector3());
  const viewerPosition = new Vector3(); // left it to preserve previous correction logic availability
  const [depth, setDepth] = useState(0.15);
  const [lookAt, setLookAt] = useState<Vector3 | null>(null);
  const [lookFrom, setLookFrom] = useState<Vector3 | null>(null);
  const [target, setTarget] = useState(viewerPosition || new Vector3());
  const [normal, setNormal] = useState(new Vector3(0, 0, 1));
  const [defaultTarget, setDefaultTarget] = useState<Vector3 | null>(null);
  const [defaultNormal, setDefaultNormal] = useState<Vector3 | null>(null);
  const setDefaultDepth = () => setDepth(DEFAULT_SECTION_DEPTH);
  const resetSectionTargetToDefault = () => {
    setTarget(new Vector3(0, 0, 0));
    setNormal(new Vector3(0, 0, 1));
  }
  const [sectionVisibility, setSectionVisibility] = useState(false);
  const azimuthAngle = useRef<number>(0);

  const setAzimuthAngle = (angle: number) => (azimuthAngle.current = angle);

  // RECLASSIFY
  const [actionMode, setActionMode] = useState(ValidationTool.Inspection_View);
  const [reclassTool, setReclassTool] = useState(RECLASS_TOOLS.BRUSH);
  const [brushRadius, setBrushRadius] = useState(12);
  const [reclassPoints, setReclassPoints] = useState<any>([]);
  const [reclassStates, setReclassStates] = useState<any>([]);

  const [toasts, setToasts] = useState<Toast[]>([]);
  const [panoramicLoading, setPanoramicLoading] = useState(false);
  const [positionLocal, setPositionLocal] = useState<any>(currentTree?.location_local || []);
  const [editing, setEditing] = useState<string>('height');

  const pointCloudModule = usePointCloud({ currentTree: currentTree as Tree });

  const { pointCloud: las } = pointCloudModule;
  const isLoading = !las || !currentTree?.location || loading || contextsLoadingState || panoramicLoading;
  const lockView = !!errorPoints.length || !!reclassPoints.length;

  const pointCloudCorrectedPosition = useMemo(
    () =>
      new Vector3(
        positionLocal[0] - (las?.pc?.mins?.[0] || 0),
        positionLocal[1] - (las?.pc?.mins?.[1] || 0),
        positionLocal[2] - (las?.pc?.mins?.[2] || 0)
      ),
    [positionLocal, las]
  );

  const locationDelta = useMemo(() => {
    const { pointCloud } = pointCloudModule;

    if (!currentTree?.id || !currentTree?.location_local || !pointCloud?.pc?.mins) return new Vector3();
    const mins = new Vector3().fromArray(pointCloud?.pc?.mins);

    const treeLocation = new Vector3().fromArray(currentTree.location_local)

    const initialPosition = mins.clone().sub(treeLocation?.clone() || new Vector3());

    return initialPosition;
  }, [currentTree?.id, currentTree?.location_local, pointCloudModule]);

  const treeLocation = useMemo(() => {
    const { pointCloud } = pointCloudModule;

    if (!currentTree?.id || !currentTree?.location_local || !pointCloud?.pc?.mins) return new Vector3();

    const treeLocation = new Vector3().fromArray(currentTree.location_local)

    return treeLocation.clone();
  }, [currentTree?.id, currentTree?.location_local, pointCloudModule])

  const firstBifurcationCoordinates: Number3 = useMemo(() => {
    if (currentTree?.first_bifurcation_point) {
      const { coordinates } = JSON.parse((currentTree?.first_bifurcation_point as unknown) as string);
      return coordinates;
    }
    return positionLocal;
  }, [currentTree?.first_bifurcation_point, positionLocal]);

  const firstBifurcationDelta = useMemo(
    () =>
      new Vector3(
        firstBifurcationCoordinates[0] - (las?.pc?.mins?.[0] || 0),
        firstBifurcationCoordinates[1] - (las?.pc?.mins?.[1] || 0),
        firstBifurcationCoordinates[2] - (las?.pc?.mins?.[2] || 0)
      ),
    [firstBifurcationCoordinates, las]
  );

  const loadTreeStatus = (tree: Partial<Tree> | null) => {
    setFirstBifurcationPoint((tree?.first_bifurcation_point as unknown) as string || getDefaultFirstBifurcation());
    setPositionLocal(tree?.location_local || []);
    setGirth1({ ...getDefaultGirthForSemanticMover(), ...parseGirth(tree as Tree) });
    setShownGirth((prev: Girth) => ({ ...DEFAULT_ELLIPSE, local_id: Date.now().toString(36) + Math.random().toString(36).substring(2), ...parseGirth(tree as Tree) }));
    setDepth(DEFAULT_SECTION_DEPTH);
    const HEIGHT_FOR_GIRTH_MEASUREMENT = 1;
    setTarget(new Vector3(viewerPosition.x, viewerPosition.y, HEIGHT_FOR_GIRTH_MEASUREMENT));
    setTmsCategory(tree?.tms_category);
  };

  useEffect(() => {
    loadTreeStatus(currentTree);
  }, [currentTree]);

  useEffect(() => {
    const firstBifurcation = JSON.parse(firstBifurcationPoint);
    const isFirstBifurcationSet = !firstBifurcation.isInitial;
    const isDeltaNotSet = [firstBifurcationDelta.x, firstBifurcationDelta.y, firstBifurcationDelta.z].some((coord) => isNaN(coord));

    if (isDeltaNotSet) return;

    if (isFirstBifurcationSet) {
      const [x, y, z] = firstBifurcation.coordinates.map((coordinate: number, index: number) => {
        const key = indexToAxisMap[index] as keyof THREE.Vector3;
        const deltaCoordinate = firstBifurcationDelta[key] as number;
        return coordinate - firstBifurcationCoordinates[index] + deltaCoordinate;
      });

      setExactFirstBifurcation((prevState) => ({ ...prevState, x, y, z }));
      return;
    }

    setExactFirstBifurcation((prevState) => ({
      x: firstBifurcationDelta.x,
      y: firstBifurcationDelta.y,
      z: firstBifurcationDelta.z,
      min: firstBifurcationDelta.z - exactFirstBifurcationRangeOffset,
      max: firstBifurcationDelta.z + exactFirstBifurcationRangeOffset,
    }));
  }, [firstBifurcationPoint, positionLocal, pointCloudCorrectedPosition, currentTree, firstBifurcationCoordinates, firstBifurcationDelta]);

  const onExactFirstBifurcationChange = (payload: ExactFirstBifurcation) =>
    setExactFirstBifurcation((prevState) => {
      const state = { ...prevState, ...payload };

      const coordinates = [state.x, state.y, state.z].map((coordinate, index) => {
        if (!payload.z && index === 2) return trunkHeight + positionLocal[2];
        const key = indexToAxisMap[index] as keyof THREE.Vector3;
        const deltaCoordinate = firstBifurcationDelta[key] as number;
        return coordinate + firstBifurcationCoordinates[index] - deltaCoordinate;
      });

      const firstBifab = JSON.stringify({ ...JSON.parse(firstBifurcationPoint), coordinates, isInitial: false });
      firstBifurcationPointHandler(firstBifab);
      return state;
    });

  const showToast = (msg: string, type: ToastType) => {
    setToasts((toasts: Toast[]) => [...toasts, { msg, type }]);
    setTimeout(() => setToasts((toasts: Toast[]) => toasts.slice(1)), 6000);
  };

  const changeReclassTool = (newTool: 'BRUSH' | 'POLYGON') => {
    if (!RECLASS_TOOLS[newTool]) {
      setReclassTool(RECLASS_TOOLS.BRUSH);
    } else if (reclassTool === RECLASS_TOOLS[newTool]) {
      return;
    } else {
      setReclassTool(RECLASS_TOOLS[newTool]);
    }

    setReclassPoints([]);
  };

  function changeHandler<T>(
    setState: StateSetter<T>,
    prev: RefType<T>,
    timeout: RefType<NodeJS.Timeout>,
    action: ChangeHandlerActionTypes
  ) {
    return (val: T) => {
      // todo: this now commits the changes when the user stopped moving the mouse
      // for 240 ms, even if she still presses the button.
      // I think it would be better to have a 'changeInProgressHandler' and 'changeDoneHandler',
      // implemented in the lower levels, and also in ActionStack, so here we don't need
      // a lot of duplication

      setState((prevState: T) => {
        if (!prev.current) prev.current = prevState;

        if (timeout.current !== null) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          onAction(action, null, prev.current);
          prev.current = val;
        }, 240);

        return val;
      });
    }
  };

  const _handleUndo = (action: ChangeHandlerActionTypes, target: any, from: any, to: any) => {
    if (action === 'SKIP') return setCurrentIndex(from);
    if (action === 'UPDATE') return updateTree(from);
    if (action === 'POSITION') return setPositionLocal(from);

    setEditing(action);

    if (action === 'height') {
      setHeight(from);
      heightPrev.current = from;
    }
    if (action === 'canopyHeight') {
      setCanopyHeight(from);
      canopyHeightPrev.current = from;
    }
    if (action === 'trunkHeight') {
      setTrunkHeight(from);
      trunkHeightPrev.current = from;
    }
    if (action === 'girth1') {
      setGirth1(from);
      girth1Prev.current = from;
    }
    if (action === 'canopy') {
      setCanopy(from);
      canopyPrev.current = from;
    }
    if (action === 'firstBifurcationPoint') {
      setFirstBifurcationPoint(from);
      firstBifurcationPointPrev.current = from;
    }
  };

  const { onAction, isUndoAvailable } = useActionStack(_handleUndo);

  const canUndo = useMemo(() => {
    switch (actionMode) {
      case ValidationTool.Error:
        return !!errorPoints.length;
      case ValidationTool.Reclassify:
        return !!reclassStates.length || !!reclassPoints.length;
      default:
        return isUndoAvailable;
    }
  }, [actionMode, errorPoints.length, isUndoAvailable, reclassPoints.length, reclassStates.length]);

  const handleErrorUndo = () => {
    if (errorPoints.length > 0) {
      setErrorPoints((val) => val.slice(0, -1));
    }
  };

  const addErrorPoint = (point: any) => {
    if (errorTool === ERROR_TOOLS.POINT) {
      setErrorPoints([point]);
    } else {
      setErrorPoints((points) => [...points, point]);
    }
  };

  const handleCancelError = () => {
    setErrorPoints([]);
    if (dismissModal) {
      dismissModal();
    }
  };

  const handleSaveError = async (comment: any, azimuthAngle: any, polarAngle: any, zoom: any, worldPos: any, worldDir: any) => { // TODO
    const payload = {
      errorType: errorTool,
      points: errorPoints.map((p) => p.point),
      treeId: currentTree?.id,
      comment,
      azimuthAngle,
      polarAngle,
      cameraScale: zoom,
      cameraPosition: { x: worldPos.x, y: worldPos.y, z: worldPos.z },
      cameraDirectionVector: { x: worldDir.x, y: worldDir.y, z: worldDir.z },
    };
    try {
      await saveError(payload);
      if (dismissModal) {
        dismissModal();
      }
      setErrorPoints([]);
    } catch (err) {
      console.error('Error during tree error save:', err);
    }
  };

  const changeErrorTool = (newTool: ERROR_TOOLS) => {
    if (!ERROR_TOOLS[newTool]) { // TODO CHECK
      setErrorTool(ERROR_TOOLS.POINT);
    } else if (errorTool === ERROR_TOOLS[newTool]) {
      return;
    } else {
      setErrorTool(ERROR_TOOLS[newTool]);
    }

    setErrorPoints([]);
    if (dismissModal) {
      dismissModal();
    }
  };

  const _handleSkip =
    (direction = 1) =>
      () => {
        const { index, nextIndex } = moveToNeighbour(direction);
        onAction('SKIP', null, index, nextIndex);
      };

  const value: SegmentationScreenContextValue = {
    pointCloudModule,

    section: {
      depth,
      lookAt,
      lookFrom,
      target,
      normal,
      defaultTarget,
      defaultNormal,
      visibility: sectionVisibility,
      setDepth,
      setLookAt,
      setLookFrom,
      setTarget,
      setNormal,
      setDefaultTarget,
      setDefaultNormal,
      setDefaultDepth,
      resetSectionTargetToDefault,
      setVisibility: setSectionVisibility
    },

    treeMetrics: {
      height,
      canopyHeight,
      trunkHeight,
      firstBifurcationPoint,
      firstBifurcationCoordinates,
      firstBifurcationDelta,
      exactFirstBifurcation,
      girth1,
      canopy,
      tmsCategory,

      heightHandler,
      canopyHeightHandler,
      trunkHeightHandler,
      firstBifurcationPointHandler,
      girth1Handler,
      canopyHandler,
      onExactFirstBifurcationChange,
      setTmsCategory
    },

    trunkActions: {
      shownGirth,
      updateGirth: () => { },
      setShownGirth
    },

    visibility: {
      firstBifurcationPoint: false,
      girth: true,
    },

    validation: {
      isFirstBifurcationMidPointCompleted: true,  // SectionView usage
      isTrunkDetailsCompleted: true,              // SectionView usage
    },

    actions: {
      canUndo,
      setAzimuthAngle,
      _handleSkip,
    },

    helper: {
      locationDelta,
      treeLocation,
      pointCloudCorrectedPosition,
      viewerPosition: new Vector3(),
      positionLocal,
      isEditingDisabled: false,
      activeTool,
      setActiveTool,
      errorTool,
      errorPoints,
      toasts,
      showToast,
      setPanoramicLoading,
      isLoading,
      editing,
      setEditing,
      lockView,
      azimuthAngle
    },

    reclass: {
      actionMode,
      reclassTool,
      brushRadius,
      reclassPoints,
      reclassStates,
      setActionMode,
      changeReclassTool,
      setBrushRadius,
      setReclassPoints,
      setReclassStates,
    },

    error: {
      changeErrorTool,
      addErrorPoint,
      handleSaveError,
      handleCancelError,
      handleErrorUndo
    },
  };

  return <SegmentationScreenContext.Provider value={value}>
    {children}
  </SegmentationScreenContext.Provider>
};